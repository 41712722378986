<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <div class="text-center">
          <a class="footerNavLink" href="/about-us">About Us</a>
          <a class="footerNavLink" href="/contact-us">Contact Us</a>
          <a class="footerNavLink" href="/privacy-policy">Privacy Policy</a>
          <a class="footerNavLink" href="/terms-and-conditions">Terms & Conditions</a>
          <a class="footerNavLink" href="/refund-policy">Refund & Cancellation</a>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'BottomNavigation',
  components: {
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>
<style scoped>
.footerNavLink {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin: 5px;
  color: #FDFAF1 !important;
  font-variant: all-small-caps;
}
</style>
