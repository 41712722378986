import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import kiraakLogo from '../components/icons/kiraakLogo.vue'
import homeIconActive from '../components/icons/homeIconActive.vue'
import homeIconInactive from '../components/icons/homeIconInactive.vue'
import customersIconActive from '../components/icons/customersIconActive.vue'
import customersIconInactive from '../components/icons/customersIconInactive.vue'
import catalogIconActive from '../components/icons/catalogIconActive.vue'
import catalogIconInactive from '../components/icons/catalogIconInactive.vue'
import basketIconActive from '../components/icons/basketIconActive.vue'
import basketIconInactive from '../components/icons/basketIconInactive.vue'
import paymentIconActive from '../components/icons/paymentIconActive.vue'
import paymentIconInactive from '../components/icons/paymentIconInactive.vue'
import houseNoIcon from '../components/icons/houseNoIcon.vue'
import locationIcon from '../components/icons/locationIcon.vue'
import xMarkIcon from '../components/icons/xMarkIcon.vue'
import cartBasketIcon from '../components/icons/cartBasketIcon.vue'

import manIcon from '../components/icons/manIcon.vue'
import womanIcon from '../components/icons/womanIcon.vue'

import rupeeIcon from '../components/icons/rupeeIcon.vue'
import rupeeIcon2 from '../components/icons/rupeeIcon2.vue'


import notAvailableIcon from '../components/icons/notAvailableIcon.vue'
import boxIcon from '../components/icons/boxIcon.vue'

import boxActiveIcon from '../components/icons/boxActiveIcon.vue'
import boxInactiveIcon from '../components/icons/boxInactiveIcon.vue'

import cartIcon from '../components/icons/cartIcon.vue'

import rocketIcon from '../components/icons/rocketIcon.vue'

import hiIcon from '../components/icons/hiIcon.vue'


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            kiraakLogo: { // name of our custom icon
            component: kiraakLogo, // our custom component
          },
            homeIconActive: {
            component: homeIconActive,
          },
            homeIconInactive: {
            component: homeIconInactive,
          },
          paymentIconInactive: {
            component: paymentIconInactive,
          },
          paymentIconActive: {
            component: paymentIconActive,
          },
            customersIconActive: {
            component: customersIconActive,
          },
            customersIconInactive: {
            component: customersIconInactive,
          },
            catalogIconActive: {
            component: catalogIconActive,
          },
            catalogIconInactive: {
            component: catalogIconInactive,
          },
            basketIconActive: {
            component: basketIconActive,
          },
            basketIconInactive: {
            component: basketIconInactive,
          },
            rupeeIcon: {
            component: rupeeIcon,
          },
          rupeeIcon2: {
          component: rupeeIcon2,
        },
          manIcon: {
            component: manIcon,
          },
          womanIcon: {
            component: womanIcon,
          },
          houseNoIcon: {
            component: houseNoIcon,
          },
          locationIcon: {
            component: locationIcon,
          },
          xMarkIcon: {
            component: xMarkIcon,
          },
          cartBasketIcon: {
            component: cartBasketIcon,
          },
          notAvailableIcon: {
            component: notAvailableIcon,
          },
          boxIcon: {
            component: boxIcon,
          },
          boxActiveIcon: {
            component: boxActiveIcon,
          },
          boxInactiveIcon: {
            component: boxInactiveIcon,
          },
          cartIcon: {
            component: cartIcon
          },
          rocketIcon: {
            component: rocketIcon
          },
          hiIcon: {
            component: hiIcon
          },
        },
      },
});
