<template>
  <v-app>
    <v-main class="mb-3">
      <router-view class="mt-14 mb-16"></router-view>
    </v-main>
    <BottomNavigation />
  </v-app>
</template>

<script>
import BottomNavigation from './components/static/BottomNavigation.vue'
export default {
  name: 'App',
  components: {
    BottomNavigation
  },
  data () {
    return {
    }
  },
  methods: {

  },
  mounted () {
  }
}
</script>
<style scoped>
</style>
