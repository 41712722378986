import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'createOrder',
      component: () => import('./components/static/AboutUs.vue')
    },
    {
      path: '/about-us',
      name: 'aboutUs',
      component: () => import('./components/static/AboutUs.vue')
    },
    {
      path: '/contact-us',
      name: 'contactUs',
      component: () => import('./components/static/ContactUs.vue')
    },
    {
      path: '/privacy-policy',
      name: 'privacyPolicy',
      component: () => import('./components/static/PrivacyPolicy.vue')
    },
    {
      path: '/terms-and-conditions',
      name: 'termsAndConditions',
      component: () => import('./components/static/TermsAndConditions.vue')
    },
    {
      path: '/refund-policy',
      name: 'refundPolicy',
      component: () => import('./components/static/RefundPolicy.vue')
    },
    {
      path: '/payments/razorpaycallback',
      name: 'paymentCallback',
      component: () => import('./components/PaymentStatus.vue')
    },
    {
      path: '/order/:orderId',
      name: 'orderStatus',
      component: () => import('./components/OrderStatus.vue')
    },
    {
      path: '/:partnerSlug/',
      name: 'createOrder',
      component: () => import('./components/static/AboutUs.vue')
    },
  ]
})
